<template>
  <div class="page">
    <webHeader :currentIndex="currentIndex"></webHeader>
    <div class="topV">
      <!-- 产品列表 -->
      <div class="topLeftV">
        <div class="titleTv">{{ $t("financial.nav_title") }}</div>
        <div class="topListV">
          <van-list
            v-model="productLoading"
            :finished="productFinished"
            :loading-text="$t('jia-zai-zhong')"
            @load="getWMPList"
          >
            <div
              class="topItemV"
              v-for="(productItem, productIndex) in productList"
              :key="productIndex"
            >
              <div class="leftItemV1">
                <img class="leftItemIv" :src="productItem.icon" />
                <div class="leftItemTv1">{{ productItem.name }}</div>
              </div>
              <div class="leftItemV2">
                <div class="leftItemTv2">
                  {{ $t("financial.list.items[0]") }}
                </div>
                <div class="leftItemTv3">
                  {{ productItem.min }}~{{ productItem.max }}
                </div>
              </div>
              <div class="leftItemV2">
                <div class="leftItemTv2">
                  {{ $t("financial.list.items[1]") }}
                </div>
                <div class="leftItemTv3">{{ productItem.day_rate }}%</div>
              </div>
              <div class="leftItemV2">
                <div class="leftItemTv2">
                  {{ $t("financial.list.items[2]") }}
                </div>
                <div class="leftItemTv3">
                  {{ productItem.days }}{{ $t("financial.list.sufix") }}
                </div>
              </div>
              <div
                class="leftItemV2"
                style="align-items: flex-end; justify-content: flex-end;"
              >
                <div
                  class="leftItemTv4 pointer"
                  @click="onBuyClick(productItem)"
                >
                  {{ $t("financial.list.button") }}
                </div>
              </div>
            </div>
            <van-row
              class="noDate"
              v-if="productList && productList.length == 0"
            >
              <van-empty />
            </van-row>
          </van-list>
        </div>
      </div>
      <!-- 产品详细 -->
      <div class="topRightV">
        <div class="titleTv">{{ $t("purchase.nav_title") }}</div>
        <div v-if="productDetails" class="productInfo">
          <img class="productInfoIv" :src="productDetails.icon" />
          <div
            class="leftItemV2"
            style="margin-left: 15px;flex: 0.5;align-items: baseline;"
          >
            <div class="productInfoTv1">{{ $t("purchase.top.title") }}</div>
            <div class="productInfoTv2">{{ productDetails.name }}</div>
          </div>
          <div class="leftItemV2">
            <div class="productInfoTv1">{{ $t("purchase.top.income") }}</div>
            <div class="productInfoTv3">{{ productDetails.day_rate }}%</div>
          </div>
          <div class="leftItemV2" style="align-items: baseline;">
            <div class="productInfoTv1">
              {{ $t("purchase.financialCycle") }}：{{ productDetails.days
              }}{{ $t("financial.list.sufix") }}
            </div>
            <div class="productInfoTv1">
              {{ $t("purchase.limitation") }}：{{ productDetails.min }}~{{
                productDetails.max
              }}
            </div>
          </div>
        </div>
        <div v-if="productDetails" class="illustrateV">
          <div class="illustrateItem">
            <span class="illustrateItemTv1">
              {{ $t("purchase.payout_time") }}
            </span>
            <span class="illustrateItemTv2">
              {{ $t("purchase.MaturitySettlement") }}
            </span>
          </div>
          <div class="illustrateItem">
            <span class="illustrateItemTv1">
              {{ $t("purchase.EscrowFunds") }}
            </span>
            <span class="illustrateItemTv2">222</span>
          </div>
          <div class="illustrateItem">
            <span class="illustrateItemTv1">
              {{ $t("purchase.redemption") }}
            </span>
            <span class="illustrateItemTv2">{{ productDetails.wy_rate }}%</span>
          </div>
          <div class="illustrateItem">
            <span class="illustrateItemTv1">{{ $t("purchase.estimate") }}</span>
            <span class="illustrateItemTv2">{{ estimate }}</span>
          </div>
          <div class="illustrateItem">
            <span class="illustrateItemTv1">
              {{ $t("purchase.availableAssets") }}
            </span>
            <span class="illustrateItemTv2">{{ available }}</span>
          </div>
        </div>
        <div class="inputV">
          <span class="inputTv1">{{ $t("trade.amount") }}</span>
          <div class="inputBg">
            <input
              v-model="value"
              :placeholder="$t('trade.input')"
              oninput="this.value=this.value.replace(/[^\d.]/g,'').replace(/(\.\d+)\.+/g, '$1')"
            />
            <span class="inputBgTv">USDT</span>
          </div>
          <span class="inputTv2 pointer" @click="onAllClick">{{
            $t("All")
          }}</span>
        </div>
        <div class="txtV">
          <span class="txtTv1">{{ $t("lockup.title") }}：</span>
          <span class="txtTv2">{{ $t("lockup.desc") }}</span>
        </div>
        <div style="margin-left: 20px; margin-right: 20px;margin-top: 20px;">
          <van-button @click="onSubmitClick">
            {{ $t("purchase.subscribe") }}
          </van-button>
        </div>
      </div>
    </div>
    <div class="bottomV">
      <div class="topLeftV">
        <div class="tabV">
          <div
            class="pointer"
            :class="tabPos == 0 ? 'tabVItem' : 'tabVItemTo'"
            @click="onTabClick(0)"
          >
            {{ $t("subscribe.tabs[0]") }}
          </div>
          <div
            class="pointer"
            :class="tabPos == 1 ? 'tabVItem' : 'tabVItemTo'"
            @click="onTabClick(1)"
          >
            {{ $t("subscribe.tabs[1]") }}
          </div>
        </div>
        <van-list
          v-model="orderLoading"
          :finished="orderFinished"
          :loading-text="$t('jia-zai-zhong')"
          @load="getOrderList"
        >
          <div
            class="topItemV"
            v-for="(orderItem, orderIndex) in orderList"
            :key="orderIndex"
          >
            <div
              class="leftItemV1"
              style="flex-direction: column;align-items: flex-start;justify-content: center;"
            >
              <div style="display: flex;align-items: center;">
                <img
                  class="leftItemIv"
                  style="width: 35px;height: 35px; border-radius: 50%;"
                  :src="orderItem.icon"
                />
                <div class="leftItemTv1">{{ orderItem.name }}</div>
              </div>
              <div style="display: flex;align-items: center;margin-top: 10px;">
                <span style="color: rgba(255, 255, 255, 0.65);font-size: 12px;">
                  {{ $t("financial.list.items[1]") }}
                </span>
                <span style="color: #fff;font-size: 12px;margin-left: 10px;">
                  {{ orderItem.day_rate }}%
                </span>
              </div>
            </div>
            <div class="leftItemV2">
              <div class="leftItemTv2">{{ $t("subscribe.money") }}</div>
              <div class="leftItemTv3">{{ orderItem.money }}</div>
            </div>
            <div v-if="tabPos == 0" class="leftItemV2">
              <div class="leftItemTv2">{{ $t("subscribe.yield") }}</div>
              <div class="leftItemTv3">{{ orderItem.income }}</div>
            </div>
            <div v-else class="leftItemV2">
              <div class="leftItemTv2">
                {{
                  orderItem.status == 1
                    ? $t("subscribe.total revenue")
                    : $t("subscribe.liquidated damages")
                }}
              </div>
              <div class="leftItemTv3">
                {{
                  orderItem.status == 1 ? orderItem.income : orderItem.d_money
                }}
              </div>
            </div>
            <div v-if="tabPos == 0" class="leftItemV2">
              <div class="leftItemTv2">{{ $t("subscribe.DaysRemaining") }}</div>
              <div class="leftItemTv3">{{ orderItem.days }}</div>
            </div>
            <div class="leftItemV2">
              <div class="leftItemTv2">{{ $t("subscribe.time") }}</div>
              <div class="leftItemTv3">
                {{ orderItem.createtime | dateformat }}
              </div>
            </div>
            <div
              v-if="tabPos == 0"
              class="leftItemTv4 pointer"
              @click="onRedeemClick(orderItem)"
            >
              {{ $t("subscribe.redeem") }}
            </div>
          </div>
          <van-row class="noDate" v-if="orderList && orderList.length == 0">
            <van-empty />
          </van-row>
        </van-list>
      </div>
      <div class="topRightV" v-if="fundData">
        <div class="titleTv">{{ $t("financial.header.desc") }}</div>
        <div class="moneyV">
          <span class="moneyTv1">
            {{ fundData.orderMoney ? fundData.orderMoney : 0 }}
          </span>
          <span class="moneyTv2">USDT</span>
        </div>
        <div class="recordV">
          <span class="recordTv1">{{ $t("financial.header.items[0]") }}：</span>
          <span class="recordTv2">{{ fundData.todayProfit }}</span>
        </div>
        <div class="recordV">
          <span class="recordTv1">{{ $t("financial.header.items[1]") }}：</span>
          <span class="recordTv2">
            {{ fundData.totalProfit ? fundData.totalProfit : 0 }}
          </span>
        </div>
        <div class="recordV">
          <span class="recordTv1">{{ $t("financial.header.items[2]") }}：</span>
          <span class="recordTv2">{{ fundData.orderCount }}</span>
        </div>
      </div>
    </div>
    <van-popup
      :close-on-click-overlay="false"
      v-model="showDialog"
      style="background-color:transparent; overflow:hidden;"
    >
      <div class="loadingWrap">
        <van-loading v-if="showDialog" type="spinner" />
      </div>
    </van-popup>
    <van-dialog v-model:show="redeem.isShow" :showConfirmButton="false">
      <div class="redeem">
        <div class="tips">
          <span>{{ $t("webAssets.tips") }}</span>
        </div>
        <div class="content">
          <span>{{ $t("subscribe.tips.0") }}</span>
          <span>
            {{
              (
                (redeem.item.wy_rate / 100) *
                redeem.item.days *
                redeem.item.money
              ).toFixed(2)
            }}
          </span>
          <span>{{ $t("subscribe.tips.1") }}</span>
        </div>
        <div class="buttons">
          <van-button
            type="primary"
            size="small"
            @click="redeem.isShow = false"
          >
            {{ $t("common.cancel") }}
          </van-button>
          <div style="width: 10px;"></div>
          <van-button type="primary" size="small" @click="onRedeem">
            {{ $t("common.confirm") }}
          </van-button>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import webHeader from "@/components/web/webHeader.vue";
export default {
  components: { webHeader },
  data() {
    return {
      showDialog: false,
      currentIndex: 5,
      value: "",
      tabPos: 0,
      productLoading: false,
      productFinished: false,
      productPage: 0,
      productList: [],
      productId: null, //产品id
      productDetails: null, //产品详情
      fundData: null, //托管资金
      estimate: 0, //预估收益
      available: 0, //可用余额
      orderLoading: false,
      orderFinished: false,
      orderList: [],
      orderPage: 0,
      orderStatus: 1, //1托管中   2已完成
      redeem: {
        isShow: false,
        item: {},
      },
    };
  },
  watch: {
    value: {
      handler(val) {
        const value =
          val * (this.productDetails.day_rate / 100) * this.productDetails.days;
        this.estimate = value == 0 ? value : value.toFixed(2);
      },
    },
  },
  created() {
    this.getWMPOrderData();
    this.getMoney();
  },
  methods: {
    //托管中、已完成
    onTabClick(e) {
      this.tabPos = e;
      this.orderPage = 0;
      if (this.tabPos == 0) {
        this.orderStatus = 1;
      } else {
        this.orderStatus = 2;
      }
      this.getOrderList();
    },
    //获取理财产品列表
    async getWMPList() {
      this.productPage++;
      const { data } = await this.$http.get(
        "/home/index/wmpList?page=" + this.productPage
      );
      if (data) {
        this.productLoading = false;
        if (data.code === 200) {
          if (data.data.length == 0) {
            this.productFinished = true;
          }
          if (this.productPage == 1) {
            this.productList = data.data;
            this.productId = this.productList[0].id;
            this.getWMPDetail();
          } else {
            this.productList.push(...data.data);
          }
        }
      }
    },
    //选择产品
    onBuyClick(item) {
      this.productId = item.id;
      this.getWMPDetail();
    },
    //获取理财产品详情
    async getWMPDetail() {
      const { data } = await this.$http.post(
        "/home/index/wmpInfo?id=" + this.productId
      );
      if (data) {
        if (data.code === 200) {
          this.productDetails = data.data;
        }
      }
    },
    //获取余额
    async getMoney() {
      this.$http.get("/home/user/index").then((res) => {
        if (res.data.code == 200) {
          const { total = "0" } = res.data.data.balance;
          this.available = total;
        }
      });
    },
    onAllClick() {
      this.value = this.available;
    },
    //获取正在托管的资金
    async getWMPOrderData() {
      const { data } = await this.$http.post("/home/home/wmpOrderData");
      if (data) {
        if (data.code === 200) {
          this.fundData = data.data;
        }
      }
    },
    //认购
    onSubmitClick() {
      if (this.value == "") {
        return this.$toast(this.$t("purchase.placeholder"));
      }
      // if (this.value > this.available) {
      //   return this.$toast(this.$t("余额不足"));
      // }
      if (
        parseFloat(this.value) < this.productDetails.min ||
        parseFloat(this.value) > this.productDetails.max
      ) {
        return this.$toast(this.$t("purchase.limitationPlaceholder"));
      }
      this.showDialog = true;
      this.$http
        .post("/home/home/wmpOrder", {
          product_id: this.productId,
          total: this.value,
        })
        .then((res) => {
          this.showDialog = false;
          if (res.data.code == 200) {
            this.$toast(this.$t("purchase.success"));
            this.getWMPOrderData();
            this.getMoney();
            this.orderPage = 0;
            this.getOrderList();
          } else {
            this.$toast(this.$t(res.data.msg));
          }
        })
        .catch((err) => {
          this.$toast(this.$t("common.actionok"));
        });
    },
    async getOrderList() {
      this.orderPage++;
      const { data } = await this.$http.get(
        "/home/home/wmpOrderList?page=" +
          this.orderPage +
          "&status=" +
          this.orderStatus
      );
      if (data) {
        this.orderLoading = false;
        if (data.code === 200) {
          if (data.data.list.length == 0) {
            this.orderFinished = true;
          }
          if (this.orderPage == 1) {
            this.orderList = data.data.list;
          } else {
            this.orderList.push(...data.data.list);
          }
        }
      }
    },
    async onRedeemClick(item) {
      this.redeem.item = item;
      this.redeem.isShow = true;
    },
    async onRedeem() {
      this.redeem.isShow = false;
      this.$http
        .post("/home/home/wmpBack", { id: this.redeem.item.id })
        .then((res) => {
          if (res.data.code == 200) {
            this.orderPage = 0;
            this.getOrderList();
            this.getWMPOrderData();
            this.$toast(this.$t("common.actionok"));
          } else if (res.data.code === 4001) {
            this.$toast(this.getlang("余额不足", localStorage.getItem("lang")));
          } else {
            this.$toast(this.$t("common.actionfail"));
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  background-color: #141416;
  min-height: 100vh;
  padding-bottom: 20px;
}

.topV {
  display: flex;
  height: 50vh;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.titleTv {
  background-color: #25262b;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 17px;
  color: #fff;
  padding-left: 14px;
}

.topLeftV {
  flex: 6;
  display: flex;
  flex-direction: column;
}

.topRightV {
  flex: 4;
  margin-left: 10px;
}

.topListV {
  background-color: #17181c;
  flex: 1;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.topItemV {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 0.1px solid #b7bbc4;
  padding-top: 25px;
  padding-bottom: 25px;
}

.leftItemV1 {
  display: flex;
  align-items: center;
  flex: 1;
}

.leftItemIv {
  width: 32px;
  height: 32px;
}

.leftItemTv1 {
  margin-left: 15px;
  color: #fff;
  font-family: Alibaba PuHuiTi;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}

.leftItemV2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.leftItemTv2 {
  color: rgba(255, 255, 255, 0.7);
  font-family: Alibaba PuHuiTi;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.leftItemTv3 {
  color: #fff;
  font-family: Alibaba PuHuiTi;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

.leftItemTv4 {
  height: 30px;
  border-radius: 5px;
  background: #f0b82d;
  color: #fff;
  font-family: Alibaba PuHuiTi;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
}

.productInfo {
  margin-top: 10px;
  background: #1f2531;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.productInfoIv {
  width: 45px;
  height: 45px;
}

.productInfoTv1 {
  font-size: 15px;
  color: #fff;
}

.productInfoTv2 {
  font-size: 17px;
  color: #fff;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 600;
}

.productInfoTv3 {
  font-size: 17px;
  color: #f0b82d;
  font-family: Alibaba PuHuiTi;
  font-style: normal;
  font-weight: 600;
}

.illustrateV {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding-left: 20px;
  padding-right: 20px;
}

.illustrateItem {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.illustrateItemTv1 {
  color: rgba(255, 255, 255, 0.65);
  font-family: Alibaba PuHuiTi;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.illustrateItemTv2 {
  color: #fff;
  font-family: Alibaba PuHuiTi;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-left: 10px;
}

.inputV {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 10px;
}

.inputTv1 {
  color: rgba(255, 255, 255, 0.65);
  font-family: Alibaba PuHuiTi;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.inputTv2 {
  color: #fff;
  font-family: Alibaba PuHuiTi;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;
}

.inputBg {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  font-size: 15px;

  input {
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
  }
}

.inputBgTv {
  color: var(--unnamed, #777);
  text-align: right;
  font-family: Alibaba PuHuiTi;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.txtV {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background: #25262b;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
}

.txtTv1 {
  color: rgba(255, 255, 255, 0.65);
  font-family: Alibaba PuHuiTi;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.txtTv2 {
  color: #fff;
  font-family: Alibaba PuHuiTi;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.van-button {
  height: 2.5rem;
  background-color: #f0b90a;
  border-radius: 1px solid #f0b90a;
  color: #fff;
  width: 100%;
}

.van-button--default {
  border: 1px solid #f0b90a;
}

.bottomV {
  display: flex;
  height: 50vh;
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.moneyV {
  display: flex;
  align-items: baseline;
  padding-left: 20px;
  margin-top: 20px;
}

.moneyTv1 {
  color: #fff;
  font-family: Alibaba PuHuiTi;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
}

.moneyTv2 {
  color: #fff;
  font-family: Alibaba PuHuiTi;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-left: 3px;
}

.recordV {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 20px;
}

.recordTv1 {
  color: rgba(255, 255, 255, 0.65);
  font-family: Alibaba PuHuiTi;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.recordTv2 {
  color: rgba(255, 255, 255, 0.65);
  font-family: Alibaba PuHuiTi;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.tabV {
  display: flex;
  align-items: center;
  background-color: #25262b;
  padding-left: 14px;
}

.tabVItem {
  font-size: 17px;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0b90a;
  padding-left: 10px;
  padding-right: 10px;
}

.tabVItemTo {
  font-size: 17px;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #00000000;
  padding-left: 10px;
  padding-right: 10px;
}

// .van-cell{
//   padding: 10px 0px;
//   padding-right: 10px;
// }

.redeem {
  padding: 20px 15px;

  .tips {
    color: #333;
    font-size: 14px;
    font-weight: 600;
  }

  .content {
    text-align: left;
    margin-top: 15px;
    color: #333;
    font-size: 12px;
    font-weight: 400;
  }

  .buttons {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    /deep/.van-button--primary {
      flex: 1;
      border-radius: 5px;
      background: #f0b90a;
      border: none;
      font-weight: 500;
    }

    /deep/.van-button--primary:first-child {
      border-radius: 5px;
      background: #e3e6ea;
      border: none;
      color: #363636;
    }
  }
}
</style>
